import React from 'react';
import Img   from 'gatsby-image';
import { Link } from 'gatsby';


import * as S from './styles';

const Display = ({ items, noLink, colCount }) => {
  return (
    <S.Container>
      {items.map(item => {
          const { title, link, image, description } = item;
          return <S.Item colCount={colCount ? colCount : items.length} key={title} as={noLink ? 'div' : Link} to={link}>
            <div>
              <Img fluid={image.childImageSharp.fluid} alt={title}/>
              <h4>{title}</h4>
            </div>
            <p dangerouslySetInnerHTML={{ __html: description }}/>
          </S.Item>;
        }
      )}
    </S.Container>
  )
};

export default Display;