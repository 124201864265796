import React                       from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Display from './Display/Display';

const Team = () => {
  let team = [];
  const data = useStaticQuery(query);
  data.team.nodes.forEach(node => {
    team.push({
      title: node.name,
      description: node.translations[0].description,
      image: node.portrait.localFile
    })
  });
  return <Display items={team} colCount={team.length} noLink/>
};

export default Team;

const query = graphql`
    query {
        team: allDirectusTeamMembers(filter: {id: {ne: "dummy"}}) {
            nodes {
                name
                portrait {
                    localFile {
                        name
                        childImageSharp {
                            fluid(maxWidth: 530, quality: 95) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                translations {
                    language
                    description
                }
            }
        }
    }
`;
